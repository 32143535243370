<template>
  <v-col class="custom-scroll mr-10">
    <v-row class="rowFertility">
      <custom-label16
        label="Nome do índice"
        class="font-weight-bold"
        style="flex: 0.4;"/>
      
      <router-link
        to=""
        style="flex: 0.5;"
        v-model="checkFertility"
        @click.native="checkFertilityIndexes"
      >

        <custom-label12 label="Marcar todos"/>
      </router-link>

      <custom-label16 label="Mostrar" class="font-weight-bold"/>
    </v-row>

    <div class="container containerFertility"
      v-for="fertilityIndex in fertilityIndexes"
      :key="fertilityIndex.id">

      <hr/>

      <v-row class="rowFertility">
        <custom-label14
          :label="fertilityIndex.name"
          style="flex: 0.9; padding-top: 1.2rem;"/>

        <span>
          <v-checkbox
            v-model="indexesStatusFertility[fertilityIndex.id]"
            color="#CFA545"
            @change="checkboxIndexes(
              fertilityIndex.id, fertilityIndex.phase.record.id)"
          />
        </span>
      </v-row>
    </div>
    <hr/>
  </v-col>
</template>

<script>
  import FertilityIndexes from "@/domain/indexes/fertility-indexes/fertility-indexes";
  import Contracts from "@/domain/contracts/contracts";

  import CustomLabel12 from "@/components/texts/CustomLabel12";
  import CustomLabel14 from "@/components/texts/CustomLabel14";
  import CustomLabel16 from "@/components/texts/CustomLabel16";

  export default {
    name: 'ProducerPanelFertility',

    components: {
      CustomLabel12,
      CustomLabel14,
      CustomLabel16
    },

    props: {
      switchFertility: {
        type: Boolean,
        default: false
      },

      producerId: {
        type: Number
      }
    },

    data: () => ({
      fertilityIndexService:null,
      contractService: null,

      fertilityIndexes: [],
      enabledFertilityIndexes: [],

      indexesStatusFertility: [],
      indexesRecordsFertility: [],

      fertility: {
        'indexesStatus': {},
        'indexesRecords': {}
      },

      checkFertility: false
    }),

    watch: {
      switchFertility(newValue) {
        this.indexesStatusFertility = {...this.indexesStatusFertility};

        this.fertilityIndexes.map(item => {
          this.indexesStatusFertility[item.id]  = newValue;
          this.indexesRecordsFertility[item.id] = item.phase.record.id;
        });

        this.addIndexesStatusRecords();
      }
    },

    methods: {
      loadFertilityIndexes() {
        const loader = this.$loading.show();

        this.fertilityIndexes = [];

        this.fertilityIndexService
          .all()
          .then((result) => {
            result.data.map((item) => {
              this.fertilityIndexes.push(item);
            });
          });

        loader.hide();
      },

      async loadEnabledFertilityIndexes() {
        this.enabledFertilityIndexes = [];

        await this.contractService
          .enabledIndexesByFertilityAndProducer(this.producerId)
          .then((result) => {
            result.data.map((item) => {
              this.enabledFertilityIndexes.push(item);
            });
          });

        this.indexesStatusFertility = {...this.indexesStatusFertility};

        this.enabledFertilityIndexes.map(item => {
          this.indexesStatusFertility[item.index_id] = item.status;
        });
      },

      checkboxIndexes(indexId, recordId) {
        this.indexesRecordsFertility[indexId] = recordId;
        this.addIndexesStatusRecords();
      },

      checkFertilityIndexes() {
        this.checkFertility = !this.checkFertility;

        this.fertilityIndexes.map(item => {
          this.indexesStatusFertility[item.id]  = this.checkFertility;
          this.indexesRecordsFertility[item.id] = item.phase.record.id;
        });

        this.addIndexesStatusRecords();
      },

      addIndexesStatusRecords() {
        Object.assign(this.fertility['indexesStatus'], {...this.indexesStatusFertility});
        Object.assign(this.fertility['indexesRecords'], {...this.indexesRecordsFertility});

        this.$emit('fertility', this.fertility);
      }
    },

    mounted() {
      this.loadFertilityIndexes();
      this.loadEnabledFertilityIndexes();
    },

    beforeMount() {
      this.fertilityIndexService = FertilityIndexes;
      this.contractService       = Contracts;
    }
  }
</script>

<style scoped>
  .v-application a {
    color: #3880FF;
  }

  .custom-scroll {
    height: 18rem;
    overflow: auto;
    overflow-x: hidden;
    padding-left: 2rem;
  }

  .rowFertility {
    margin: 0;
  }

  .containerFertility {
    padding: 8px 0 0 0;
    margin-bottom: -12px;
  }
</style>
