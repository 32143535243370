<template>
  <div>
    <span class="header">
      {{ label }}
    </span>
  </div>
</template>

<script>
  export default {
    name: "CustomLabel12",
    props: {
      label: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped>
  .header {
    font-size: 12px;
    color: #3880FF;
    line-height: 24px;
    letter-spacing: 0.14px;
  }
</style>
