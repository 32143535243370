import http from "@/services/http.service";

const apiUrl = "/financial-indexes";

class FinancialIndexesService {
  static all() {
    return http.get(`${apiUrl}`);
  }
}

export default FinancialIndexesService;
