import PostFloweringIndexesService from './post-flowering-indexes.service';

class PostFloweringIndex {
  constructor(payload = {}) {
    Object.assign(this, {
      ...PostFloweringIndex.build(payload),
    });
  }

  static build(
    {
      id       = '',
      name     = '',
      phase_id = '',
      phase    = {}
    }
  ) {
    return {
      id,
      name,
      phase_id,
      phase
    };
  }

  static newData(payload) {
    return new PostFloweringIndex(payload);
  }

  static all() {
    return new Promise((resolve, reject) => {
      PostFloweringIndexesService
        .all()
        .then(
          success => {
            const postFloweringIndexes = success;
            postFloweringIndexes.data  = postFloweringIndexes
              .data
              .map(item => this.newData(item));
            resolve(postFloweringIndexes);
          },

          error => {
            reject(error);
          }
        );
    });
  }
}

export default PostFloweringIndex;
