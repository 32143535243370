import PostPlantingIndexesService from './post-planting-indexes.service';

class PostPlantingIndex {
  constructor(payload = {}) {
    Object.assign(this, {
      ...PostPlantingIndex.build(payload),
    });
  }

  static build(
    {
      id       = '',
      name     = '',
      phase_id = '',
      phase    = {}
    }
  ) {
    return {
      id,
      name,
      phase_id,
      phase
    };
  }

  static newData(payload) {
    return new PostPlantingIndex(payload);
  }

  static all() {
    return new Promise((resolve, reject) => {
      PostPlantingIndexesService
        .all()
        .then(
          success => {
            const postPlantingIndexes = success;
            postPlantingIndexes.data  = postPlantingIndexes
              .data
              .map(item => this.newData(item));
            resolve(postPlantingIndexes);
          },

          error => {
            reject(error);
          }
        );
    });
  }
}

export default PostPlantingIndex;
