<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title class="form-modal-header">
        <v-btn color="white" text @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span class="text-h5 white--text">
          Painel do Produtor
        </span>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="save"> Salvar </v-btn>
      </v-card-title>

       <v-card-text class="mt-12">
        <v-container>
          <v-row class="mb-5">
            <v-toolbar elevation="0" style="background: none">
              <v-toolbar-title>
                <custom-label18 label="Registro de Manejo"/>
              </v-toolbar-title>

              <v-switch
                class="ml-5"
                color="#3880FF"
                v-model="switchManagement"
              />
            </v-toolbar>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col cols="4">
              <custom-label18 class="text-center" label="Pós - Plantio"/>
            </v-col>

            <v-col cols="4">
              <custom-label18 class="text-center" label="Pós - Florescimento"/>
            </v-col>

            <v-col cols="4">
              <custom-label18 class="text-center" label="Colheita"/>
            </v-col>
          </v-row>
        </v-container>

        <producer-panel-management
          :switchManagement="switchManagement"
          :producerId="producerId"
          @management="addIndexesStatusRecords($event)"
        />

        <v-container>
          <v-row>
            <v-col cols="6" class="pl-0">
              <v-toolbar elevation="0" style="background: none;">
                <v-toolbar-title style="flex: 0.985;">
                  <custom-label18 label="Registro de Fertilidade"/>
                </v-toolbar-title>

                <v-switch
                  class="ml-5"
                  color="#3880FF"
                  v-model="switchFertility"
                />
              </v-toolbar>
            </v-col>

            <v-col cols="6" class="pr-0 pl-5">
              <v-toolbar elevation="0" style="background: none">
                <v-toolbar-title style="flex: 1;">
                  <custom-label18 label="Registro Financeiro"/>
                </v-toolbar-title>

                <v-switch
                  class="ml-5"
                  color="#3880FF"
                  v-model="switchFinancial"
                />
              </v-toolbar>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row class="d-flex justify-space-between">
            <producer-panel-fertility
              class="custom-container mb-10"
              :switchFertility="switchFertility"
              :producerId="producerId"
              @fertility="addIndexesStatusRecords($event)"
            />

            <producer-panel-financial
              class="custom-container mb-10"
              :switchFinancial="switchFinancial"
              :producerId="producerId"
              @financial="addIndexesStatusRecords($event)"
            />
          </v-row>
        </v-container>
       </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import Contracts from "@/domain/contracts/contracts";
  import FertilityIndexes from "@/domain/indexes/fertility-indexes/fertility-indexes";
  
  import ProducerPanelManagement from './ProducerPanelManagement.vue';
  import ProducerPanelFertility from './ProducerPanelFertility.vue';
  import ProducerPanelFinancial from './ProducerPanelFinancial.vue';
  
  import CustomLabel18 from "@/components/texts/CustomLabel18.vue";

  export default {
    name: 'ProducerPanel',

    components: {
      ProducerPanelManagement,
      ProducerPanelFertility,
      ProducerPanelFinancial,
      CustomLabel18
    },

    props: {
      dialog: {
        type: Boolean,
        default: false
      },

      producerId: {
        type: Number
      }
    },
  
    data: () => ({
      payload: {},

      fertilityIndexService:null,

      switchManagement: false,
      switchFertility: false,
      switchFinancial: false,

      indexesStatus: [],
      indexesRecords: []
    }),

    methods: {
      addIndexesStatusRecords(event) {
        const response = {...event};

        Object.assign(this.indexesStatus, response['indexesStatus']);
        Object.assign(this.indexesRecords, response['indexesRecords']);
      },

      close() {
        this.$emit("closeDialog");
      },

      save() {
        const loader = this.$loading.show();

        this.payload.producer_id     = this.producerId;
        this.payload.indexes_status  = {...this.indexesStatus};
        this.payload.indexes_records = {...this.indexesRecords};

        this.contractService
          .storeOrUpdate(this.payload)
          .then(success => {
            loader.hide();
            this.close();

            const {data} = success;
            const response = Object.assign({}, {...data});

            this.$toasted.show(response.message, {
              type: "success",
            });
          })
          .catch(() => {
            loader.hide();

            this.$toasted.show("Erro no salvamento do contrato.", {
              type: "error",
            });
          });
      }
    },

    beforeMount() {
      this.payload         = Contracts.newData();
      this.contractService = Contracts;
      this.fertilityIndexService = FertilityIndexes;
    }
  }
</script>

<style scoped>
  ::v-deep .v-toolbar__content {
    padding: 0;
  }

  ::v-deep .v-toolbar .v-input {
    padding-top: 1.4rem;
  }

  ::v-deep .v-input--switch__thumb {
    top: calc(50% - 7px);
    left: calc(50% - 11px);
    height: 15px;
    width: 15px;
    background-color: #ffffff;
  }

  ::v-deep .v-input--switch__track {
    height: 16px;
    left: 8px;
    opacity: 1.0;
  }

  .custom-container {
    border: 1px solid #e3e3e3;
    border-radius:5px;
    padding: 1.5rem 2rem 0.75rem 2rem;
  }

  p {
    font-weight: bold;
    color: #000000;
  }
</style>
