import http from "@/services/http.service";

const apiUrl = "/post-planting-indexes";

class PostPlantingIndexesService {
  static all() {
    return http.get(`${apiUrl}`);
  }
}

export default PostPlantingIndexesService;
