<template>
  <div>
    <div v-if="openDetails" class="mt-4 font-weight-bold">
      <v-icon @click="closeView()"> mdi-arrow-left </v-icon>&nbsp;
      <span style="margin-left: 0.5rem;">Voltar</span>
    </div>

    <toolbar
      :toolbarName="dynamicToolbarName"
      :openDetails="openDetails"
      :authoritySlug="authoritySlug"
      :permissions="permissions.access_producers"
    />

    <form-dialog
      :dialog="formDialog"
      :formName="dynamicFormName"
    >
      <v-form ref="formProducer">
        <v-toolbar flat v-if="canToggleUser">
          <v-switch
            v-model="payload.is_active"
            :label="getStatus(payload.is_active)"
            color="red"
            @change="toggleUser(payload.id)"
            :value="payload.is_active"
            hide-details
          ></v-switch>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-row>
          <v-col>
            <h2>Informações do Produtor</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="4"
            sm="6"
          >
            <custom-label label="Nome"/>
            <simple-text-field v-model="payload.name"
                              required
                              placeholder="Nome"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            sm="6"
          >
            <custom-label label="CPF"/>
            <simple-text-field v-model="payload.cpf"
                              required
                              mask="cpf"
                              placeholder="CPF"
            />
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Tipo de Área" />
            <simple-select :items="areaTypeItems" v-model="payload.producer.area_type" itemText="text" itemValue="value"
              placeholder="Sementeiro" :menuProps="{ bottom: true, offsetY: true, closeOnClick: true }" required
              :rules="requiredRules" />
          </v-col>
          <v-col
            cols="12"
            md="4"
            sm="6"
          >
            <custom-label label="Sementeiro"/>
            <simple-select :items="isSowerItems"
                          v-model="payload.producer.is_sower"
                          itemText="name"
                          itemValue="value"
                          placeholder="Sementeiro"
                          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true}"
                          required
                          :rules="requiredRules"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            sm="6"
          >
            <custom-label label="Área"/>
            <simple-select :items="areaItems"
              v-model="payload.producer.farm_size"
              itemText="text"
              itemValue="text"
              placeholder="Selecione a Área"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true}"
              required
              :rules="requiredRules"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            sm="6"
          >
            <custom-label label="Irrigação"/>
            <simple-select :items="irrigationItems"
                          v-model="payload.producer.irrigation"
                          itemText="text"
                          itemValue="text"
                          placeholder="Selecione a irrigação"
                          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true}"
                          required
                          :rules="requiredRules"
            />

          </v-col>
          <v-col
            cols="12"
            md="4"
            sm="6"
          >
            <custom-label label="AS Responsável"/>
            <simple-select :items="successAdvisors"
                          v-model="payload.producer.responsible_success_advisor_id"
                          itemText="name"
                          itemValue="id"
                          placeholder="Selecione o AS Responsável"
                          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true}"
                          required
                          :rules="requiredRules"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            sm="6"
          >
            <span class="font-weight-bold">As Complementar?</span>
            <v-checkbox
              v-model="hasComplementarySuccessAdvisor"
              label="AS Complementar"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            md="4"
            sm="6"
          >
            <custom-label label="AS Complementar"/>
            <simple-select :items="successAdvisors"
                          :disabled="!hasComplementarySuccessAdvisor"
                          v-model="payload.producer.complementary_success_advisor_id"
                          itemText="name"
                          itemValue="id"
                          placeholder="Selecione o AS Complementar"
                          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true}"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            sm="6"
          >
            <custom-label label="Estado"/>
            <simple-select :items="states"
                          v-model="payload.state_id"
                          itemText="name"
                          itemValue="id"
                          placeholder="Selecione o Estado"
                          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 130}"
                          required
                          :rules="requiredRules"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            sm="6"
          >
            <custom-label label="Telefone"/>
            <simple-text-field v-model="payload.phone"
                              mask="phone"
                              placeholder="Insira o Telefone"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h2>Informações da Conta</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="4"
            sm="6"
          >
            <custom-label label="E-mail"/>
            <simple-text-field v-model="payload.email"
                              email
                              required
                              placeholder="Insira o E-mail"
            />
          </v-col>

          <v-col cols="12" md="4" sm="6" v-if="!openDetails">
            <custom-label label="Status"/>
            <simple-select
              v-model="payload.is_active"
              :items="statusType"
              itemText="text"
              itemValue="value"
              class="mr-2"
              placeholder="Status"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
            />
          </v-col>

          <v-col cols="12" md="4" sm="6" v-if="openDetails">
            <span class="font-weight-bold">Senha</span> <br/>
            
            <primary-button
                label="Nova Senha"
                style="margin-top: 0.3rem;"
                type="button"
                @callAction="resetPassword"
            />
          </v-col>
        </v-row>
      </v-form>
    </form-dialog>

    <search-bar v-if="!openDetails" v-model="search" @externalFiltersClear="clearFilters" :noSecondaryInput="false">
      <simple-select
          v-model="stateSearch"
          :items="states"
          @input="filterByState()"
          itemText="uf"
          itemValue="uf"
          class="mr-2"
          placeholder="Estado"
          height="0"
      />
    </search-bar>

    <row-details v-if="openDetails" :show="openDetails"
      :authoritySlug="authoritySlug" :permissions="permissions.access_producers">
      <v-row align="center">
        <v-col class="d-flex justify-start">
          <custom-label18 label="Configurações de Índices"/>
        </v-col>

        <v-col class="d-flex justify-end">
          <primary-button
            label="Configurar"
            @callAction="showConfigDialog = true">
          </primary-button>
        </v-col>
      </v-row>

      <template v-slot:[`other-row-details`]>
        <v-row align="center">
          <v-col class="d-flex justify-start">
            <span class="font-weight-bold">Informações</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="d-flex justify-end">
            <v-icon
              v-if="authoritySlug === 'super-admin' || permissions.access_producers"
              @click="edit()"> mdi-pencil</v-icon>
          </v-col>
        </v-row>
        <v-row>
          <col-details v-if="payload.name != null" name="Nome" :item="payload.name"/>
          <col-details v-if="payload.producer != null" name="CPF" :item="payload.producer.cpf" sensitive mask="cpf"/>
          <col-details v-if="payload.email != null" name="E-mail" :item="payload.email"/>
        </v-row>

        <v-row>
          <col-details v-if="payload.state != null" column="3" name="Estado" :item="payload.state.uf"/>
          <col-details column="3" name="Status" :item="getStatus(payload.is_active)"/>
          
          <v-col cols="3" v-if="payload.producer != null">
            <p>
              <span><b>AS Responsável</b></span> <br/>
              <router-link to="" @click.native="viewAS(payload.producer)">
                <span class="font-weight-light">
                  {{ payload.producer.responsible_success_advisor.name }}
                </span>
              </router-link>
            </p>
          </v-col>
          
          <col-details v-if="payload.producer != null" column="3" name="Qtd. de Fazendas" :item="payload.producer.farms.length"/>
        </v-row>
      </template>
    </row-details>

    <data-table
      :headers="headers"
      :fetching="fetching"
      :data="data"
      :objectName="objectName"
      :search="search.text"
      @loadAgain="loadAgain"
      v-else
    >
      <template v-slot:[`item.producer.farms`]="{ item }">
        {{ item.producer.farms.length }}
      </template>

      <template v-slot:[`item.is_active`]="{ item }">
        {{ getStatus(item.is_active) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          class="ml-4"
          small
          @click="view(item)"
        >
          mdi-information-outline
        </v-icon>

        <v-icon
          v-if="authoritySlug === 'super-admin' || permissions.access_producers"
          class="ml-4"
          small
          @click="deleteItem(item)"
        >
          mdi-trash-can-outline
        </v-icon>
      </template>
    </data-table>

    <producer-panel
      v-if="showConfigDialog"
      :dialog="showConfigDialog"
      :producerId="payload.producer.id"
      @closeDialog="closeConfigDialog"
    />

    <confirm-reset-password-dialog
      :payload="this.payload"
      :dialog.sync="showConfirmResetPasswordDialog"
      @closeDialog="closeConfirmResetPasswordDialog"
    />

    <confirm-destroy-dialog
      :title="payload.name"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />
  </div>
</template>

<script>
import DataTable from "@/components/data-table/DataTable";
import Toolbar from "@/components/crud/Toolbar";
import FormDialog from "@/components/crud/FormDialog";
import RowDetails from "@/components/crud/Details";
import ColDetails from "@/components/crud/DetailsItem";
import SimpleTextField from "@/components/inputs/SimpleTextField.vue";
import SearchBar from "@/components/layouts/SearchBar.vue";
import SimpleSelect from "@/components/inputs/SimpleSelect.vue";
import Users from "@/domain/users/users";
import Producers from "@/domain/producers/producers";
import Authorities from "@/domain/authorities/authorities";
import States from "@/domain/states/states";
import ProducerPanel from "@/views/pages/users/producers/ProducerPanel.vue";
import CustomLabel18 from "@/components/texts/CustomLabel18.vue";
import CustomLabel from "@/components/texts/CustomLabel";
import PageableMixin from "@/support/utils/pageable.mixin";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import ConfirmResetPasswordDialog from "@/components/dialogs/login/ConfirmResetPasswordDialog";
import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";
import {getItem} from '@/services/local-storage.service';

export default {
  name: "Producers",
  mixins: [PageableMixin],
  components: {
    DataTable,
    Toolbar,
    FormDialog,
    RowDetails,
    ColDetails,
    SimpleTextField,
    SearchBar,
    SimpleSelect,
    ProducerPanel,
    CustomLabel18,
    CustomLabel,
    PrimaryButton,
    ConfirmResetPasswordDialog,
    ConfirmDestroyDialog
},
  data() {
    return {
      objectName: "produtores",
      toolbarName: "Lista de Produtores",
      formName: "Adicionar Produtor",

      fetching: true,
      search: {},
      stateSearch: "",
      data: [],
      producerSelected: {},
      authorities: [],
      states: [],
      successAdvisors: [],

      statusType:
        [
          {text: "Ativo", value: true},
          {text: "Inativo", value: false}
        ],

      userService: null,
      authorityService: null,
      stateService: null,

      payload: null,
      selected: null,
      canToggleUser: false,

      slug: "producer",
      authority_id: 0,

      openDetails: false,
      formDialog: false,
      showConfigDialog: false,
      showConfirmResetPasswordDialog: false,
      showConfirmDestroyDialog: false,

      isSowerItems: [
        { name: "Sim", value: true },
        { name: "Não", value: false },
      ],
      irrigationItems: [
        {text: "Sem irrigação"},
        {text: "Sim, menos de 50% da área"},
        {text: "Sim, mais de 50% da área"}
      ],
      areaItems: [
        {text: "0 a 500"},
        {text: "500 a 1000"},
        {text: "1000 a 3000"},
        {text: "acima de 3000"},
      ],
      areaTypeItems: [
        {value:1,text: "Própria"},
        {value:2,text: "Arrendada"},
      ],

      hasComplementarySuccessAdvisor: false,

      authoritySlug: '',
      permissions: '',

      requiredRules: [v => (!!v || v === false) || "O campo é obrigatório"]
    };
  },
  methods: {
    getUsers() {
      this.fetching = true;
      this.data     = [];

      this.userService.list().then((result) => {
        result.data.map((itm) => {
          if (itm.authority.slug === this.slug && itm.producer) {
            this.data.push(itm);

            this.setProducerSelected(itm);
          }
          /*
          if (itm.authority.slug === "success-advisor") {
            this.successAdvisors.push(itm);
          }
          */
        })
      })
      .catch(({response}) => {
        const {error} = response.data;
        this.$toasted.show(error, {
          type: "error",
        });
      });

      this.fetching = false;
    },
    getSuccessAdvisors() {
      this.fetching = true;
      this.data     = [];

      this.userService.getSuccessAdvisors().then((result) => {
        result.data.map((itm) => {
          this.successAdvisors.push(itm);
        })
      });

      this.fetching = false;
    },
    setProducerSelected(user) {
      if (this.$route.params.producerId === user.producer.id) {
        this.producerSelected = user;
        this.view(this.producerSelected);
      }
    },
    getAuthority() {
      let loader = this.$loading.show();

      this.authorityService.getBySlug(this.slug).then((result) => {
        result.data.map((itm) => {
          this.authority_id = itm.id;
        });
      });
      loader.hide();
    },
    getStates() {
      let loader = this.$loading.show();

      this.stateService.list().then((result) => {
        result.data.map((itm) => {
          this.states.push(itm);
        });
      });
      loader.hide();
    },
    getStatus(status) {
      if (status === true) return "Ativo";

      return "Inativo";
    },
    toggleUser(id) {
      let loader = this.$loading.show();

      Users.toggle(id)
        .then(() => {
          loader.hide();
          this.$toasted.show("Status alterado com sucesso.", {
            type: "success",
          });
        })
        .catch(({ response }) => {
          loader.hide();
          const { error } = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        });
    },
    initialize() {
      this.userService = Users;
      this.authorityService = Authorities;
      this.stateService = States;
      this.producerService = Producers;
      this.payload = this.userService.newData();

      this.authoritySlug = getItem('authoritySlug');
      this.permissions   = JSON.parse(getItem('permissions'));

      this.getUsers();
      this.getSuccessAdvisors();
      this.getAuthority();
      this.getStates();
    },
    view(item) {
      this.payload     = Object.assign({}, item);
      this.selected    = Object.assign({}, item);
      this.openDetails = true;
      this.payload.cpf = this.payload.producer.cpf;
    },
    viewAS(item) {
      if (this.authoritySlug === 'super-admin' || this.permissions.access_as) {
        this.navigateTo('success-advisors', {asId: item.responsible_success_advisor.id});
      }
    },
    closeDetails() {
      this.selected = {};
      this.openDetails = false;
    },
    create() {
      this.selected = {};
      this.payload.is_active = true;
      this.canToggleUser = false;
      this.formDialog = true;
    },
    edit() {
      this.canToggleUser = true;
      this.formDialog = true;
    },
    deleteItem(item) {
      this.payload = Object.assign({}, item);
      this.showConfirmDestroyDialog = true;
    },
    closeConfirmDestroyDialog() {
      this.showConfirmDestroyDialog = false;
    },
    closeFormDialog() {
      this.selected = {};
      this.formDialog = false;
    },
    closeConfigDialog() {
      this.showConfigDialog = false;
    },
    closeConfirmResetPasswordDialog() {
      this.showConfirmResetPasswordDialog = false;
    },
    closeView() {
      this.openDetails = false;
      this.$route.params.producerId = null;
      this.initialize();
    },
    save() {
      if (!this.$refs.formProducer.validate()) {
        return;
      }

      let loader = this.$loading.show();

      this.payload.authority_id = this.authority_id;
      this.payload.is_active = this.payload.is_active === null
        ? false : this.payload.is_active;

      if (!this.openDetails) {
        this.userService
          .create(this.payload)
          .then(() => {
            loader.hide();
            this.closeFormDialog();
            this.initialize();
            this.$toasted.show("Usuário cadastrado com sucesso.", {
              type: "success",
            });
          })
          .catch(({ response }) => {
            loader.hide();
            const { error } = response.data;
            this.$toasted.show(error, {
              type: "error",
            });
          });
      } else {
        this.userService
          .edit(this.payload)
          .then(() => {
            loader.hide();

            const stateSelected = this.states.find(
              item => item.id === this.payload.state_id
            );

            const successAdvisorSelected = this.successAdvisors.find(
              item => item.id === this.payload.producer.responsible_success_advisor_id
            );

            this.payload.producer.cpf = this.payload.cpf;
            this.payload.state.uf = stateSelected.uf;
            this.payload.producer.responsible_success_advisor.name = successAdvisorSelected.name;
            this.payload.producer.responsible_success_advisor.id = successAdvisorSelected.id;

            this.closeFormDialog();
            this.$toasted.show("Usuário atualizado com sucesso.", {
              type: "success",
            });
          })
          .catch(({ response }) => {
            loader.hide();
            const { error } = response.data;
            this.$toasted.show(error, {
              type: "error",
            });
          });
      }
    },
    async destroy() {
      const loader = this.$loading.show();

      await this.producerService.delete(this.payload.producer).then(() => {
        loader.hide();
        this.getUsers();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Produtor removido com sucesso.", {
          type: "success",
        });
      })
      .catch(() => {
        loader.hide();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Produtor não foi removido.", {
          type: 'error'
        });
      });
    },
    filterByState() {
      this.search.state = this.stateSearch;
    },
    clearFilters() {
      this.search = {};
      this.stateSearch = "";
    },
    loadAgain() {
      this.initialize();
    },
    resetPassword(){
      this.showConfirmResetPasswordDialog = true
    }
  },
  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          value: "producer.id",
          sortable: this.canBeSortable,
        },
        {
          text: "Nome do Produtor",
          value: "name",
          sortable: this.canBeSortable,
        },
        { text: "Estado", value: "state.uf", sortable: this.canBeSortable,
          filter: value => {
            if (this.stateSearch === "") return true
            return value === this.search.state;
          }},
        {
          text: "AS Responsável",
          value: "producer.responsible_success_advisor.name",
          sortable: this.canBeSortable,
        },
        {
          text: "Número de Fazendas",
          value: "producer.farms",
          sortable: this.canBeSortable,
        },
        { text: "Detalhes", value: "actions", sortable: false },
      ]
    },
    canBeSortable() {
      return !this.fetching;
    },
    dynamicToolbarName() {
      if (this.openDetails) {
        return "Detalhes do Produtor / " + this.payload.name;
      }

      return this.toolbarName;
    },
    dynamicFormName() {
      if (this.openDetails) {
        return "Editar Produtor";
      }

      return this.formName;
    }
  },
  beforeMount() {
    this.initialize();
  },
};
</script>

<style scoped>
  .v-application a {
    color: #000000DE;
  }
</style>