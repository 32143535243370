<template>
  <v-container class="custom-container mb-10">
    <v-row>
      <v-col cols="4" class="custom-scroll">
        <v-row class="rowManagement">
          <custom-label16
            label="Nome do índice"
            class="font-weight-bold"
            style="flex: 0.5;"/>

          <router-link
            to=""
            style="flex: 0.4;"
            v-model="switchPostPlanting"
            @click.native="checkPostPlantingIndexes"
            >

            <custom-label12 label="Marcar todos"/>
          </router-link>

          <custom-label16 label="Mostrar" class="font-weight-bold"/>
        </v-row>

        <div class="container containerManagement"
          v-for="postPlantingIndex in postPlantingIndexes"
          :key="postPlantingIndex.id">

          <hr/>

          <v-row class="rowManagement">
            <custom-label14
              :label="postPlantingIndex.name"
              style="flex: 0.9; padding-top: 1.2rem;"/>

            <span>
              <v-checkbox
                v-model="indexesStatusManagement[postPlantingIndex.id]"
                color="#CFA545"
                @change="checkboxIndexes(
                  postPlantingIndex.id, postPlantingIndex.phase.record.id)"
              />
            </span>
          </v-row>
        </div>
        <hr/>
      </v-col>

      <v-col cols="4" class="custom-scroll pl-7">
        <v-row class="rowManagement">
          <custom-label16
            label="Nome do índice"
            style="flex: 0.5;"
            class="font-weight-bold"/>

          <router-link
            to=""
            style="flex: 0.4; color: #4586FA;"
            v-model="switchPostFlowering"
            @click.native="checkPostFloweringIndexes"
            >

            <custom-label12 label="Marcar todos"/>
          </router-link>

          <custom-label16 label="Mostrar" class="font-weight-bold"/>
        </v-row>

        <div class="container containerManagement"
          v-for="postFloweringIndex in postFloweringIndexes"
          :key="postFloweringIndex.id">

          <hr/>

          <v-row class="rowManagement">
            <custom-label14
              :label="postFloweringIndex.name"
              style="flex: 0.9; padding-top: 1.2rem;"/>

            <span>
              <v-checkbox
                v-model="indexesStatusManagement[postFloweringIndex.id]"
                color="#CFA545"
                @change="checkboxIndexes(
                  postFloweringIndex.id, postFloweringIndex.phase.record.id)"
              />
            </span>
          </v-row>
        </div>
        <hr/>
      </v-col>

      <v-col cols="4" class="custom-scroll pl-7">
        <v-row class="rowManagement">
          <custom-label16
            label="Nome do índice"
            style="flex: 0.5;"
            class="font-weight-bold"/>

          <router-link
            to=""
            style="flex: 0.4; color: #4586FA;"
            v-model="switchHarvest"
            @click.native="checkHarvestIndexes"
            >
            
            <custom-label12 label="Marcar todos"/>
          </router-link>

          <custom-label16 label="Mostrar" class="font-weight-bold"/>
        </v-row>

        <div class="container containerManagement"
          v-for="harvestIndex in harvestIndexes"
          :key="harvestIndex.id">

          <hr/>

          <v-row class="rowManagement">
            <custom-label14
              :label="harvestIndex.name"
              style="flex: 0.9; padding-top: 1.2rem;"/>

            <span>
              <v-checkbox
                v-model="indexesStatusManagement[harvestIndex.id]"
                color="#CFA545"
                @change="checkboxIndexes(
                  harvestIndex.id, harvestIndex.phase.record.id)"
              />
            </span>
          </v-row>
        </div>
        <hr/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import PostPlantingIndexes from "@/domain/indexes/post-planting-indexes/post-planting-indexes";
  import PostFloweringIndexes from "@/domain/indexes/post-flowering-indexes/post-flowering-indexes";
  import HarvestIndexes from "@/domain/indexes/harvest-indexes/harvest-indexes";
  import Contracts from "@/domain/contracts/contracts";

  import CustomLabel12 from "@/components/texts/CustomLabel12.vue";
  import CustomLabel14 from "@/components/texts/CustomLabel14.vue";
  import CustomLabel16 from "@/components/texts/CustomLabel16.vue";

  export default {
    name: 'ProducerPanelManagement',

    components: {
      CustomLabel12,
      CustomLabel14,
      CustomLabel16,
    },

    props: {
      switchManagement: {
        type: Boolean,
        default: false
      },

      producerId: {
        type: Number
      }
    },

    data: () => ({
      switchPostPlanting: false,
      switchPostFlowering: false,
      switchHarvest: false,

      postPlantingIndexService: null,
      postFloweringIndexService: null,
      harvestIndexService: null,
      contractService: null,

      postPlantingIndexes: [],
      postFloweringIndexes: [],
      harvestIndexes: [],

      enabledManagementIndexes: [],

      indexesStatusManagement: [],
      indexesRecordsManagement: [],

      management: {
        'indexesStatus': {},
        'indexesRecords': {}
      }
    }),

    watch: {
      switchManagement(newValue) {

        this.indexesStatusManagement = {...this.indexesStatusManagement};

        this.postPlantingIndexes.map(item => {
          this.indexesStatusManagement[item.id]  = newValue;
          this.indexesRecordsManagement[item.id] = item.phase.record.id;
        });       
        
        this.postFloweringIndexes.map(item => {
          this.indexesStatusManagement[item.id]  = newValue;
          this.indexesRecordsManagement[item.id] = item.phase.record.id;
        });
       
        this.harvestIndexes.map(item => {
          this.indexesStatusManagement[item.id]  = newValue;
          this.indexesRecordsManagement[item.id] = item.phase.record.id;
        });

        this.addIndexesStatusRecords();
      }
    },

    methods: {
      async loadManagementIndexes() {
        const loader = this.$loading.show();

        this.postPlantingIndexes  = [];
        this.postFloweringIndexes = [];
        this.harvestIndexes       = [];

        await this.postPlantingIndexService
          .all()
          .then((result) => {
            result.data.map((item) => {
              this.postPlantingIndexes.push(item);
            });
          });

        await this.postFloweringIndexService
          .all()
          .then((result) => {
            result.data.map((item) => {
              this.postFloweringIndexes.push(item);
            });
          });

        await this.harvestIndexService
          .all()
          .then((result) => {
            result.data.map((item) => {
              this.harvestIndexes.push(item);
            });
          });

        loader.hide();
      },

      async loadEnabledManagementIndexes() {
        this.enabledManagementIndexes = [];

        await this.contractService
          .enabledIndexesByManagementAndProducer(this.producerId)
          .then((result) => {
            result.data.map((item) => {
              this.enabledManagementIndexes.push(item);
            });
          });

        this.indexesStatusManagement = {...this.indexesStatusManagement};

        this.enabledManagementIndexes.map(item => {
          this.indexesStatusManagement[item.index_id] = item.status;
        });
      },

      checkPostPlantingIndexes() {
        this.switchPostPlanting = !this.switchPostPlanting;

        this.postPlantingIndexes.map(item => {
          this.indexesStatusManagement[item.id]  = this.switchPostPlanting;
          this.indexesRecordsManagement[item.id] = item.phase.record.id;
        });

        this.addIndexesStatusRecords();
      },

      checkPostFloweringIndexes() {
        this.switchPostFlowering = !this.switchPostFlowering;

        this.postFloweringIndexes.map(item => {
          this.indexesStatusManagement[item.id]  = this.switchPostFlowering;
          this.indexesRecordsManagement[item.id] = item.phase.record.id;
        });

        this.addIndexesStatusRecords();
      },

      checkHarvestIndexes() {
        this.switchHarvest = !this.switchHarvest;

        this.harvestIndexes.map(item => {
          this.indexesStatusManagement[item.id]  = this.switchHarvest;
          this.indexesRecordsManagement[item.id] = item.phase.record.id;
        });

        this.addIndexesStatusRecords();
      },

      checkboxIndexes(indexId, recordId) {
        this.indexesRecordsManagement[indexId] = recordId;
        this.addIndexesStatusRecords();
      },

      addIndexesStatusRecords() {
        Object.assign(this.management['indexesStatus'], {...this.indexesStatusManagement});
        Object.assign(this.management['indexesRecords'], {...this.indexesRecordsManagement});

        this.$emit('management', this.management);
      }
    },

    mounted() {
      this.loadManagementIndexes();
      this.loadEnabledManagementIndexes();
    },

    beforeMount() {
      this.postPlantingIndexService  = PostPlantingIndexes;
      this.postFloweringIndexService = PostFloweringIndexes;
      this.harvestIndexService       = HarvestIndexes;
      this.contractService           = Contracts;
    }
  }
</script>

<style scoped>
  .v-application a {
    color: #3880FF;
  }

  .custom-container {
    border: 1px solid #e3e3e3;
    border-radius:5px;
    padding: 1.5rem 2rem 0.75rem 2rem;
  }

  .custom-scroll {
    height: 18rem;
    overflow: auto;
    overflow-x: hidden;
  }

  .rowManagement {
    margin: 0;
  }

  .containerManagement {
    padding: 8px 0 0 0;
    margin-bottom: -12px;
  }
</style>
