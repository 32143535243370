import http from "@/services/http.service";

const apiUrl = "/harvest-indexes";

class HarvestIndexesService {
  static all() {
    return http.get(`${apiUrl}`);
  }
}

export default HarvestIndexesService;
