<template>
  <v-col class="custom-scroll">
    <v-row class="rowFinancial">
      <custom-label16
        label="Nome do índice"
        class="font-weight-bold"
        style="flex: 0.4;"/>

      <router-link
        to=""
        style="flex: 0.5;"
        v-model="checkFinancial"
        @click.native="checkFinancialIndexes"
      >

        <custom-label12 label="Marcar todos"/>
      </router-link>
      
      <custom-label16 label="Mostrar" class="font-weight-bold"/>
    </v-row>

    <div class="container containerFinancial"
      v-for="financialIndex in financialIndexes"
      :key="financialIndex.id">

      <hr/>

      <v-row class="rowFinancial">
        <custom-label14
          :label="financialIndex.name"
          style="flex: 0.9; padding-top: 1.2rem;"/>

        <span>
          <v-checkbox
            v-model="indexesStatusFinancial[financialIndex.id]"
            color="#CFA545"
            @change="checkboxIndexes(
              financialIndex.id, financialIndex.phase.record.id)"
          />
        </span>
      </v-row>
    </div>
    <hr/>
  </v-col>
</template>

<script>
  import FinancialIndexes from "@/domain/indexes/financial-indexes/financial-indexes";
  import Contracts from "@/domain/contracts/contracts";

  import CustomLabel12 from "@/components/texts/CustomLabel12.vue";
  import CustomLabel14 from "@/components/texts/CustomLabel14.vue";
  import CustomLabel16 from "@/components/texts/CustomLabel16.vue";

  export default {
    name: 'ProducerPanelFertility',

    components: {
      CustomLabel12,
      CustomLabel14,
      CustomLabel16
    },

    props: {
      switchFinancial: {
        type: Boolean,
        default: false
      },

      producerId: {
        type: Number
      }
    },

    data: () => ({
      financialIndexService: null,
      contractService: null,

      financialIndexes: [],
      enabledFinancialIndexes: [],

      indexesStatusFinancial: [],
      indexesRecordsFinancial: [],

      financial: {
        'indexesStatus': {},
        'indexesRecords': {}
      },

      checkFinancial: false
    }),

    watch: {
      switchFinancial(newValue) {

        this.indexesStatusFinancial = {...this.indexesStatusFinancial};

        this.financialIndexes.map(item => {
          this.indexesStatusFinancial[item.id]  = newValue;
          this.indexesRecordsFinancial[item.id] = item.phase.record.id;
        });

        this.addIndexesStatusRecords();
      }
    },

    methods: {
      loadFinancialIndexes() {
        const loader = this.$loading.show();
        this.financialIndexes = [];

        this.financialIndexService
          .all()
          .then((result) => {
            result.data.map((item) => {
              this.financialIndexes.push(item);
            });
          });

        loader.hide();
      },

      async loadEnabledFinancialIndexes() {
        this.enabledFinancialIndexes = [];

        await this.contractService
          .enabledIndexesByFinancialAndProducer(this.producerId)
          .then((result) => {
            result.data.map((item) => {
              this.enabledFinancialIndexes.push(item);
            });
          });

        this.indexesStatusFinancial = {...this.indexesStatusFinancial};

        this.enabledFinancialIndexes.map(item => {
          this.indexesStatusFinancial[item.index_id] = item.status;
        });
      },

      checkboxIndexes(indexId, recordId) {
        this.indexesRecordsFinancial[indexId] = recordId;
        this.addIndexesStatusRecords();
      },

      checkFinancialIndexes() {
        this.checkFinancial = !this.checkFinancial;

        this.financialIndexes.map(item => {
          this.indexesStatusFinancial[item.id]  = this.checkFinancial;
          this.indexesRecordsFinancial[item.id] = item.phase.record.id;
        });

        this.addIndexesStatusRecords();
      },

      addIndexesStatusRecords() {
        Object.assign(this.financial['indexesStatus'], {...this.indexesStatusFinancial});
        Object.assign(this.financial['indexesRecords'], {...this.indexesRecordsFinancial});

        this.$emit('financial', this.financial);
      }
    },

    mounted() {
      this.loadFinancialIndexes();
      this.loadEnabledFinancialIndexes();
    },

    beforeMount() {
      this.financialIndexService = FinancialIndexes;
      this.contractService       = Contracts;
    }
  }
</script>

<style scoped>
  .v-application a {
    color: #3880FF;
  }

  .custom-scroll {
    height: 18rem;
    overflow: auto;
    overflow-x: hidden;
    padding-left: 2rem;
  }

  .rowFinancial {
    margin: 0;
  }

  .containerFinancial {
    padding: 8px 0 0 0;
    margin-bottom: -12px;
  }
</style>
