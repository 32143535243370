import http from "@/services/http.service";

const apiUrl = "/post-flowering-indexes";

class PostFloweringIndexesService {
  static all() {
    return http.get(`${apiUrl}`);
  }
}

export default PostFloweringIndexesService;
